import React,{ useState , useCallback} from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"

// We use Route in order to define the different routes of our application
import { Route, Routes, Outlet   } from "react-router-dom";
import{Container} from 'react-bootstrap';
// We import all the components we need in our app
import Navbar from "./components/navbar";
import RecordList from "./components/recordList";
import Edit from "./components/edit";
import Create from "./components/create";
import Print from "./components/print";
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-widgets/scss/styles.scss";
import StudyList from "./components/study";
const queryClient = new QueryClient();
const App = () => {
    const [selectedQRMode, setSelectedQRMode] = useState("ID");

    // This callback function will be passed to the Navbar component
    const handleQRModeChange = useCallback((qrmode) => {
        setSelectedQRMode(qrmode);
    }, []);
    return (
            <div>
                <QueryClientProvider client={queryClient}>

                    <Routes>
                        <Route path="/print/:id" element={<Print />} />
                        <Route
                            element={
                                <>
                                    <Navbar selectedQRMode={selectedQRMode} handleQRModeChange={handleQRModeChange}/>
                                    <Container>
                                        <Outlet />
                                    </Container>
                                </>
                            }
                            >
                            <Route exact path="/" element={<RecordList selectedQRMode={selectedQRMode} />} />
                            <Route path="/edit/:id" element={<Edit selectedQRMode={selectedQRMode} />} />
                            <Route path="/create" element={<Create />} />
                        </Route>
                    </Routes>
                </QueryClientProvider>

            </div>
    );
};

export default App;