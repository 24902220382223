import React, {useState} from "react";
import {Link} from "react-router-dom";
import {Form, Table} from "react-bootstrap";
import server_addr from "../config.js";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {useQuery} from '@tanstack/react-query';
import {getQrCode} from "./qrcode";
import Badge from 'react-bootstrap/Badge';

const Record = (props) => {
    const [showQRCode, setShowQRCode] = useState(false);

    const handleMouseEnter = () => {
        setShowQRCode(true);
    };

    const handleMouseLeave = () => {
        setShowQRCode(false);
    };

    return (
        <tr onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <td>
                <Link onClick={() =>  navigator.clipboard.writeText(props.record._b64id)} to={`/edit/${props.record._id}`} >{props.record._b64id} </Link>
                {showQRCode && (
                    <div style={{background: 'white', padding: '8px', height: '144px', width: '144px'}}>
                        {getQrCode(props.selectedQRMode, props.record)}
                    </div>
                )}
            </td>
            <td>{props.record.fname} {props.record.mname} {props.record.sname}</td>
            <td>{new Date(props.record.dob).toLocaleDateString(undefined,{ timeZone: 'UTC' })}</td>
        </tr>
    );
};

export default function RecordList(props) {
    const [search_name, setSearch_name] = useState("");
    const [search_id, setSearch_id ] = useState("");

    const { data: records, error , isLoading} = useQuery(
        [search_name, search_id], // Cache key
        async () => {
            const response = await fetch(`${server_addr}/?search_name=${search_name}&search_id=${search_id}`);
            if (!response.ok) {
                throw new Error(`An error occurred: ${response.statusText}`);
            }
            return response.json();
        }
    );

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    // This method will map out the records on the table
    function recordList(selectedQRMode) {
        return records.map((record) => {
            return (
                <Record
                    selectedQRMode={selectedQRMode}
                    record={record}
                    key={record._id}
                />
            );
        });
    }

    // This following section will display the table with the records of individuals.
    return (
        <div>
            <h3>Search</h3>
            <Form>
                <Form.Group controlId="search_name" as={Row} className="mb-3">
                    <Form.Label column sm="1">
                        Name
                    </Form.Label>
                    <Col sm="11">
                    <Form.Control
                        type="text"
                        placeholder="Search by patient name..."
                        value={search_name}
                        onChange={(e) => setSearch_name(e.target.value)}
                        aria-describedby="basic-addon1"
                    />
                    </Col>
                    <Form.Label column sm="1">
                        ID
                    </Form.Label>
                    <Col sm="11">
                        <Form.Control
                            type="text"
                            placeholder="Search by patient ID..."
                            value={search_id}
                            onChange={(e) => setSearch_id(e.target.value)}
                            aria-describedby="basic-addon1"
                        />
                    </Col>
                </Form.Group>
            </Form>
            <h3>Record List </h3>
            <Table striped bordered responsive>
                <thead>
                <tr>
                    <th>MSK ID</th>
                    <th>Patient Name</th>
                    <th>Date of Birth</th>
                </tr>
                </thead>
                <tbody>
                {isLoading ? (
                    <tr>
                        <td colSpan="3">Loading... <br/> If it does not succeed, please check your VPN connection.</td>
                    </tr>
                ):(recordList(props.selectedQRMode))}
                </tbody>
            </Table>
        </div>
    );
}