import QRCode from "react-qr-code";
import React from "react";

export function getQrCode(selectedQRMode, record, size = 128) {
    let dob=new Date(record.dob)
    let value=selectedQRMode === 'ID' ? record._b64id : selectedQRMode === 'MRI'?
        (record.sname?record.sname:'') + "\t" +
        (record.fname?record.fname:'') + "\t" +
        (record.prename?record.prename:'') + "\t" +
        (record.mname?record.mname:'') + "\t" +
        (record.sufname?record.sufname:'')  + "\t \tt\t" +
        dob.toLocaleDateString('en-gb') + "\t" +
        (!record.gender ? '' : record.gender[0]) + "\t" +
        record._b64id :
        (record.sname?record.sname:'') + "\t" +
        (record.fname?record.fname:'') + "\t" +
        (dob.getDate()?dob.getDate().toString().padStart(2, '0'):'') + "\t"+
        (dob.getMonth()?(dob.getMonth()+1).toString().padStart(2, '0'):'') + "\t"+
        (dob.getFullYear()?dob.getFullYear():'') + "\t\t"+
        (!record.gender ? '' : record.gender[0])
    return value?  <QRCode value={value} size={size}/>: ''

}
