import QRCode from 'react-qr-code';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import server_addr from "../config.js";
import './print.css'
import Button from 'react-bootstrap/Button';
const Print = () => {
    const { id } = useParams();
    const [patientData, setPatientData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${server_addr}/get/${id}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch patient data');
                }

                const record = await response.json();
                setPatientData(record);

            } catch (error) {
                console.error(error);
            }

        };

        fetchData();

    }, [id]);


    return (
        //add @page{ size: A4; margin: 0; } to css

        <div>
            {patientData && (
                <div >
                    <img src="/img/MSK-D BW logo-8.png" alt="logo" width="90" style={{
                        display : 'block',
                        margin : '2px auto 0 auto',
                    }
                    }/>
                    <p style={{
                        margin : '0 0 0 0',
                        // center text
                        textAlign : 'center',
                        fontSize : '0.8em',
                    }}>{patientData.fname} {patientData.sname}  </p>
                    <div style={
                        {
                            //center div
                            textAlign : 'center',

                        }
                    }>
                    <QRCode value={patientData._b64id} size={90} />
                    </div>
                    <p style={{
                        margin : '0 0 0 0',
                        textAlign : 'center',
                        // font size smaller
                        fontSize : '12px'
                    }}>{patientData._b64id}

                    </p>
                    <Button variant="primary" className="no-print"
                            style={
                        // center button
                            {
                                display : 'block',
                                margin : '28px auto 0 auto',
                                }
                            }
                            onClick={() => window.print()}>Print</Button>

                </div>
            )}
        </div>
    );
};
export default Print;
//delay 1 s and print the page

