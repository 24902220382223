import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router";
import DatePicker from "react-widgets/DatePicker";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import moment from 'moment';
import server_addr from "../config.js";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {getQrCode} from "./qrcode";
import StudyList from "./study";
require("moment/min/locales.min");

export default function Edit(props) {
    const [form, setForm] = useState({
        prename: "",
        fname: "",
        mname: "",
        sname: "",
        sufname: "",
        gender: "",
        dob: new Date(Date.UTC(1970,0,1)),
        email: "",
    });


    const params = useParams();
    const navigate = useNavigate();


    useEffect(() => {
        async function fetchData() {
            const id = params.id.toString();
            const response = await fetch(`${server_addr}/get/${id}`);

            if (!response.ok) {
                const message = `An error has occurred: ${response.statusText}`;
                window.alert(message);
                return;
            }

            const record = await response.json();
            if (!record) {
                window.alert(`Record with id ${id} not found`);
                navigate("/");
                return;
            }
            record.dob = new Date(record.dob)
            record.dob = new Date(record.dob.getUTCFullYear(),record.dob.getUTCMonth(),record.dob.getUTCDate());
            //update form by record, filter out keys that are not in form
            setForm((prev) => {
                return { ...prev, ...record };

            });

        }
        fetchData();
    }, [params.id, navigate]);
    const handleDelete = () => {
        const shouldDelete = window.confirm("Are you sure you want to delete this record?");
        if (shouldDelete) {
            deleteRecord(params.id);
        }
    };
    // These methods will update the state properties.
    function updateForm(value) {

        return setForm((prev) => {
            return { ...prev, ...value };
        });
    }
    async function handleSendpass() {
        await updateRecord();
        await fetch(`${server_addr}/sendpass/${params.id.toString()}`)
            .catch(error => {
                window.alert(error);
                return;
            })
    }
    // This method will delete a record
    async function deleteRecord(id) {

        await fetch(`${server_addr}/${id}`, {
            method: "DELETE"
        });
        navigate("/");
    }
    async function updateRecord() {
        let editedPerson = { ...form };
        const year = editedPerson.dob.getFullYear();
        const month = editedPerson.dob.getMonth();
        const date = editedPerson.dob.getDate();
        editedPerson.dob = Date.UTC(year,month,date)
        // This will send a post request to update the data in the database.
        await fetch(`${server_addr}/${params.id}`, {
            method: "PATCH",
            body: JSON.stringify(editedPerson),
            headers: {
                'Content-Type': 'application/json'
            },
        });
    }
    async function onSubmit(e) {
        e.preventDefault();
        await updateRecord();
        navigate("/");
    }
    function parseDate(value){
        let locale= new Intl.DateTimeFormat().resolvedOptions().locale
        moment.locale(locale.toLowerCase());
        let format = moment.localeData().longDateFormat('L')
        value=moment(value, format)
        return new Date(value)
    }
    // This following section will display the form that takes input from the user to update the data.
    return (
        <div>
            <Row>
                <Col sm="6">
                    <h2>Patient Record</h2>
                </Col>
                <Col sm="6">
                    <div style={
                        {
                            //center div
                            textAlign : 'center',
                        }
                    }>
                        <p style={{
                            margin : '16px  0 2px 0',
                            fontSize : '18px'
                        }}
                        >{form._b64id}</p>
                        {getQrCode(props.selectedQRMode, form, 144)}
                    </div>
                </Col>
            </Row>

            <Form.Group as={Row} className="mb-3">


            </Form.Group>
            <Form onSubmit={onSubmit}>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm="2" htmlFor="prename">Name Prefix</Form.Label>
                    <Col sm="10">
                    <Form.Control
                        type="text"
                        id="prename"
                        value={form.prename}
                        onChange={(e) => updateForm({ prename: e.target.value })}
                    />
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm="2" htmlFor="fname">First Name</Form.Label>
                    <Col sm="10">
                    <Form.Control
                        type="text"
                        id="fname"
                        value={form.fname}
                        onChange={(e) => updateForm({ fname: e.target.value })}
                    />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm="2" htmlFor="mname">Middle Name</Form.Label>
                    <Col sm="10">
                    <Form.Control
                        type="text"
                        dropdown="mname"
                        value={form.mname}
                        onChange={(e) => updateForm({ mname: e.target.value })}
                    />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm="2" htmlFor="sname">Surname</Form.Label>
                    <Col sm="10">
                    <Form.Control
                        type="text"
                        id="sname"
                        value={form.sname}
                        onChange={(e) => updateForm({ sname: e.target.value })}
                    />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm="2" htmlFor="sufname">Name Suffix</Form.Label>
                    <Col sm="10">
                    <Form.Control
                        type="text"
                        id="sufname"
                        value={form.sufname}
                        onChange={(e) => updateForm({ sufname: e.target.value })}
                    />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm="2" htmlFor="gender">Gender</Form.Label>
                    <Col sm="10">
                    <Form.Select id="gender" value={form.gender} onChange={(e) => updateForm({ gender: e.target.value })}>
                        <option>Male</option>
                        <option>Female</option>
                        <option>Others</option>
                    </Form.Select>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm="2" htmlFor="dob" >Date of Birth</Form.Label>
                    <Col sm="10">
                    <DatePicker value={form.dob} parse={(value) => parseDate(value)} onChange={(e) => updateForm({ dob: e })} />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm="2" htmlFor="email">E-mail</Form.Label>
                    <Col sm="10">
                    <Form.Control
                        type="text"
                        id="email"
                        value={form.email}
                        onChange={(e) => updateForm({ email: e.target.value })}
                    />
                    </Col>
                </Form.Group>
                <br />{/* This assumes you want to center in the viewport */}
                <Row className="justify-content-center">
                    <Col xs="auto">
                        <Button type="sendpass" onClick={handleSendpass} variant="secondary" >
                            Send Pass
                        </Button>
                    </Col>
                    <Col xs="auto">
                        <Button type="printpass" onClick={
                            () => {
                                navigate(`/print/${params.id.toString()}`)
                            }
                        } variant="secondary" >
                            Print Pass
                        </Button>
                    </Col>
                    <Col xs="auto"> {/* xs="auto" makes the column width content-fit and m-2 gives margin */}
                        <Button type="submit" variant="primary">
                            Update Record
                        </Button>
                    </Col>
                    <Col xs="auto">
                        <Button type="delete" onClick={handleDelete} variant="danger" >
                            Delete Record
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}