import React,{useEffect} from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";


const root = ReactDOM.createRoot(document.getElementById("root"));

const RedirectToDomain = () => {
    useEffect(() => {
        if (window.location.hostname !== "db.mskscience.com") {
            window.location.replace("https://db.mskscience.com");
        }
    }, []);

    return null; // This component does not render anything
};

root.render(
  <React.StrictMode>
    <BrowserRouter>
        <RedirectToDomain />
        <App />
    </BrowserRouter>
  </React.StrictMode>
);