import React, { useState } from "react";
import { useNavigate } from "react-router";
import { Form, Button } from "react-bootstrap";
import DatePicker from "react-widgets/DatePicker";
import moment from 'moment';
import server_addr from "../config.js"; 
export default function Create() {
  let default_form={
    prename: "",
    fname: "",
    mname: "",
    sname: "",
    sufname: "",
    gender: "Male",
    dob: new Date(1970,0,1),
    email:"",
  }
  const [form, setForm] = useState(default_form);
  const navigate = useNavigate();

  // These methods will update the state properties.
  function updateForm(value) {
    return setForm((prev) => {
      return { ...prev, ...value };
    });
  }

  // This function will handle the submission.
  async function onSubmit(e) {
    e.preventDefault();

    // When a post request is sent to the create url, we'll add a new record to the database.
    let newPerson = { ...form };
    const year = newPerson.dob.getFullYear();
    const month = newPerson.dob.getMonth();
    const date = newPerson.dob.getDate();
    newPerson.dob = Date.UTC(year,month,date)

    await fetch(`${server_addr}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newPerson),
    })
      .catch(error => {
        window.alert(error);
        return;
      });

    setForm(default_form);
    navigate("/");
  }

  function parseDate(value){
    let locale= new Intl.DateTimeFormat().resolvedOptions().locale
    moment.locale(locale.toLowerCase());
    let format = moment.localeData().longDateFormat('L')
    value=moment(value , format)
    return new Date(value)
  }

  // This following section will display the form that takes the input from the user.
  return (
    <div>
      <h3>Create New Patient</h3>
      <Form onSubmit={onSubmit}>
        <Form.Group>
          <Form.Label htmlFor="prename">Name Prefix</Form.Label>
          <Form.Control
            type="text"
            id="prename"
            value={form.prename}
            onChange={(e) => updateForm({ prename: e.target.value })}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label htmlFor="fname">First Name</Form.Label>
          <Form.Control
            type="text"
            id="fname"
            value={form.fname}
            onChange={(e) => updateForm({ fname: e.target.value })}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label htmlFor="mname">Middle Name</Form.Label>
          <Form.Control
            type="text"
            dropdown="mname"
            value={form.mname}
            onChange={(e) => updateForm({ mname: e.target.value })}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label htmlFor="sname">Surname</Form.Label>
          <Form.Control
            type="text"
            id="sname"
            value={form.sname}
            onChange={(e) => updateForm({ sname: e.target.value })}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label htmlFor="sufname">Name Suffix</Form.Label>
          <Form.Control
            type="text"
            id="sufname"
            value={form.sufname}
            onChange={(e) => updateForm({ sufname: e.target.value })}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label htmlFor="Gender">Gender</Form.Label>
          <Form.Select id="gender" value={form.gender} onChange={(e) => updateForm({ gender: e.target.value })}>
            <option>Male</option>
            <option>Female</option>
            <option>Others</option>
          </Form.Select>
        </Form.Group>
        <Form.Group>
          <Form.Label htmlFor="dob">Date of Birth</Form.Label>
          <DatePicker value={form.dob} id="dob" parse={(value) => parseDate(value)} onChange={(e) => updateForm({ dob: e })} />
        </Form.Group>
        <Form.Group>
          <Form.Label htmlFor="email">E-mail</Form.Label>
          <Form.Control
              type="text"
              id="email"
              value={form.email}
              onChange={(e) => updateForm({ email: e.target.value })}
          />
        </Form.Group>
        <br />
        <Button type="submit" variant="primary">
          Create person
        </Button>
      </Form>
    </div>
  );
}