// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@page  {
    size: 30mm 40mm;
    margin: 2px;
}

@media print {
    .no-print, .no-print *
    {
        display: none !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/print.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,WAAW;AACf;;AAEA;IACI;;QAEI,wBAAwB;IAC5B;AACJ","sourcesContent":["@page  {\n    size: 30mm 40mm;\n    margin: 2px;\n}\n\n@media print {\n    .no-print, .no-print *\n    {\n        display: none !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
