
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router";
import DatePicker from "react-widgets/DatePicker";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import moment from 'moment';
import server_addr from "../config.js";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {getQrCode} from "./qrcode";
import {useQuery} from "@tanstack/react-query";

require("moment/min/locales.min");


// "studies": [
//     {
//         "_id": ""
//         "patient_id": ""
//         "study_date": "16/05/2023",
//         "injury_locations": [
//             "Cervical Spine",
//             "Right Knee"
//         ],
//         "voice_recordings": [
//             {
//                 "date": "16/05/2023",
//                 "file_path": "minio://bucketname/study_id/series_id"
//             }
//         ],
//         "mris": [
//             {
//                 "study_date": "study_date",
//                 "study_description": "Cervical Spine",
//                 "machine": "S-Scan",
//                 "study_id":  "1.3.76.2.1.1.4.1.2.7692.739368431",
//                 "series": [
//                     {
//                         "seq_name": "XBONE",
//                         "series_id":  "1.3.76.2.1.1.4.1.3.7692.739369614",
//                         "image_path": "minio://bucketname/study_id/series_id"
//                     }
//                 ],
//                 "operator": "Name",
//                 "radiologist": "Name",
//                 "report_text": {
//                     "history": "text",
//                     "clinical questions": "text",
//                     "exam": "text",
//                     "findings": "text",
//                     "conclusion": "text"
//                 }
//             }
//         ],
//         "mai": [
//             {
//                 "date": "16/05/2023",
//                 "treatment_stage": "pre",
//                 "actions": [
//                     {
//                         "resolution": {
//                             "width": 1920,
//                             "height": 1080
//                         },
//                         "depth": true,
//                         "frame_rate": 30,
//                         "action_name": "sqt",
//                         "file_path": "minio://",
//                         "repeats": 3,
//                         "biomechanics":{
//                             "df":{
//                                 "0": 0.0
//                             }
//                         }
//                     }
//                 ]
//             }
//         ]
//     }
// ]
export default function StudyList() {
    //get patient id
    const { id } = useParams();
    const { data: studies, error , isLoading} = useQuery(
        [id], // Cache key
        async () => {
            const response = await fetch(`${server_addr}/study?id=${id}`);
            if (!response.ok) {
                throw new Error(`An error occurred: ${response.statusText}`);
            }
            return response.json();
        }
    );
    if (error) {
        return <div>Error: {error.message}</div>;
    }

    // This method will map out the records on the table
    function studyList() {
        return studies.map((study) => {
            return (
                <div></div>
            );
        });
    }
    return(
        <div>
            <h2>Studies</h2>



            <div>
                {studyList()}
            </div>
        </div>
        )
}